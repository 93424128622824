import { HeatingType } from '@condo/domain';

const supportEmail = "support.pm{'@'}condogroup.de";
const techSupportEmail = "tech.support.pm{'@'}condogroup.de";

export default {
    address: 'Anschrift',
    blog: {
        searchArticles: 'Blog-Beiträge suchen',
    },
    buttons: {
        refreshPage: 'Seite aktualisieren',
        redirectToHome: 'Zur Startseite',
        showAll: 'Alle anzeigen',
        previous: 'Zurück',
        download: 'Herunterladen',
        upload: 'Hochladen',
        next: 'Nächste',
        copiedToClipboard: 'Link wurde in die Zwischenablage kopiert',
        login: 'Anmelden',
        logout: 'Abmelden',
        confirm: 'Bestätigen',
        back: 'Zurück',
        selectEstate: 'Wählen Sie eine Immobilie',
    },
    common: {
        monthNth: 'Monat {month}',
        month: 'Monat | Monate',
        lastN: 'Der letzte | Die letzten',
    },
    company: {
        name: 'Condo Group GmbH',
        nameFull: 'Condo Asset Management GmbH',
        street: 'Große Elbstraße 39',
        zip: '22767',
        city: 'Hamburg',
    },
    contact: {
        phone: '+49 (0) 40 8740 564 0',
        supportEmail,
    },
    contactRequestSuccessDescription: 'Wir haben Ihre Anfrage erhalten und werden uns bald bei Ihnen melden.',
    contactRequestSuccessTitle: 'Nachricht erfolgreich gesendet',
    copyright: 'Alle Rechte vorbehalten.',
    dashboard: {
        welcomeToPortal: 'Willkommen in Ihrem Eigentümerportal!',
        overview: {
            verificationTitle: 'Verifizierung',
            verificationSubTitle: 'Bitte bestätigen Sie Ihre Email',
            verificationButtonText: 'Verifizierung erneut senden',
            verificationEmailSent: 'Verifizierungs-Email wurde gesendet',
        },
        assets: {
            linkTitle: 'Erläuterung zum Aufbau Ihrer Liquiditätsrücklage',
        },
        financials: {
            linkTitle: 'Zu den Ist-Ausschüttungen',
        },
    },
    documents: {
        noDocumentFound: {
            title: 'Keine Dokumente gefunden',
            description: 'Sie haben keine Dokumente zum Anzeigen.',
        },
        title: 'Dokumente',
        exportZipName: 'export.zip',
        selectAll: 'Alle wählen',
        downloadError: 'Download  Fehler',
        previewNotSupported: 'Vorschau wird nicht unterstützt',
    },
    errors: {
        internalErrorTitle: 'Ups! Etwas ist schief gelaufen!',
        internalErrorDescription: 'Versuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
        timeoutErrorTitle: 'Die Seite ist derzeit nicht aufrufbar.',
        timeoutErrorDescription: `Bitte versuchen Sie in ca. 5 Minuten die Seite erneut zu laden. Sollte das Problem dauerhaft bestehen, kontaktieren Sie uns bitte unter ${techSupportEmail}`,

        requiredField: 'Deine Eingabe ist erforderlich',
        consentRequired: 'Deine Zustimmung ist erforderlich',
        invalidFormat: 'Deine Eingabe hat ein ungültiges Format',
        invalidAddress: 'Bitte geben Sie die vollständige Adresse ein, einschließlich Straße, Hausnummer, Stadt und PLZ',
        tryAgain: 'Bei der Bearbeitung ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.',

        userNotFound: 'Ihre Benutzerinformationen wurden nicht gefunden',
        userNotFoundDescription: `Zur angegebenen Email Adresse gibt es kein Eigentumsverhältnis bei uns. Nutzen Sie die Email, welche Sie bei uns angegeben haben oder wenden Sie sich an ${supportEmail}`,
        fileUpload: 'Das Hochladen der Datei war nicht erfolgreich. Bitte versuchen Sie es erneut.',
        fileSizeLimitExceeded: 'Die Datei ist zu groß. Bitte wählen Sie eine kleinere Datei aus.',

        recaptchaInvalidScore: `Hoppla! 🤖
            Wir konnten Ihre Anfrage nicht überprüfen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support, wenn das Problem weiterhin besteht.`,
        recaptchaMissing: `Überprüfung fehlt!
            Es scheint, dass Sie die Sicherheitsüberprüfung verpasst haben. Bitte stellen Sie sicher, dass Sie sie abschließen.`,
    },
    footer: {
        description:
            'Als SEV-Verwaltung sind wir bestrebt, einen individuellen Service und Lösungen anzubieten, die Ihren Bedürfnissen entsprechen. Dabei verstehen wir uns als professionelle Unterstützung für die Eigentümer, sowie als hilfsbereiten Ansprechpartner für die Mieter.',
    },
    freeHotline: 'Service-Rufnummer',
    menu: {
        aboutUs: 'Über uns',
        owner: 'Eigentümer',
        manager: 'Verwalter',
        blog: 'Wissen',
        dataProtection: 'Datenschutz',
        imprint: 'Impressum',
        portal: 'Portal',
        contact: 'Kontakt',
    },
    notFoundCards: {
        homeTitle: 'Zur Startseite',
        homeDescription: 'Tauchen Sie ein und erfahren Sie alles über unsere Leistungen.',
        homeBtn: 'Neu starten',

        blogTitle: 'Unser Wissensblog',
        blogDescription: 'Lesen Sie die neuesten Beiträge in unserem Blog.',
        blogBtn: 'Letzte Beiträge anzeigen',

        contactTitle: 'Kontaktieren Sie uns',
        contactDescription: 'Sie können das Gesuchte nicht finden?',
        contactBtn: 'Sprich mit unserem Team',
    },
    notFoundDescription: 'Wir haben lange gesucht, aber nicht gefunden, wonach Sie gesucht haben. Lassen Sie uns einen besseren Ort für Sie finden.',
    notFoundTitle: 'Oh, schade! Wir haben diese Seite verloren',
    openHours: {
        title: 'Telefonische Erreichbarkeit:',
        line1: 'Mo/ Mi/ Fr 09:30 - 12:00 Uhr',
        line2: 'Die/ Do 14:00 - 17:00 Uhr',
        line3: 'Sowie nach Vereinbarung',
        note: 'Außerhalb der Sprechzeiten erreichen Sie in dringenden Fällen telefonisch 24 Stunden unser externes Service-Center über die reguläre Rufnummer.',
    },
    placeholders: {
        input: 'Bitte Eingeben',
    },
    portalAssets: {
        title: 'Informationen zur Immobilie',
        rental: {
            title: 'Vermietung',
            statusTitle: 'Vermietungsstatus',
            searchingStatus: 'Mietersuche',
            identifiedStatus: 'Mieter in Auswahl',
            rentedStatus: 'Vermietet',
            rentStartDate: 'Mietbeginn',
            nextPossibleRentIncrease: 'Nächstmögliche Mieterhöhung',
            lastColdRent: 'Vertragliche Kaltmiete (Soll)',
        },
        noMediaTitle: 'Keine Fotos',
        noMediaSubTitle: 'Leider gibt es noch keine Fotos zu dieser Wohnung.',
        address: 'Adresse',
        livingArea: 'Wohnfläche',
        rooms: 'Zimmer',
        tenant: 'Mieter',
        buildingYear: 'Baujahr',
        heatingType: 'Heizungart',
        parkingNumber: 'Stellplatz Nummer',
        rentDeposit: 'Mietkaution',
        coldRent: 'Kaltmiete',
        totalRent: 'Warmmiete',
        utilities: 'Nebenkosten exkl. Heizkosten',
        heatingCosts: 'Heizkosten',
        parkingCosts: 'Stellplatzkosten',
        liquidityReserveBuildUp: 'Aufbau Ihrer Liquiditätsrücklage (Soll)',
    },
    portalFinances: {
        rentalIncome: 'Mieteinnahmen',
        homeOwnerFee: 'Hausgeld',
        homeOwnerFeeExempt: 'Erstattung Hausgeld',
        managementFee: 'Verwaltungsgebühr',
        managementFeeExempt: 'Erstattung Verwaltungsgebühr',
        otherCosts: 'Sonstige Kosten',
        allUtilityCosts: 'Abzug Nebenkostenvorauszahlung',
        propertyTax: 'Grundsteuer',
        repaymentSubsidy: 'Tilgungszuschuss',
        regularPayment: 'Ihr regulärer Ausschüttungsbetrag',
        manualAdjustment: 'Nicht zugeordnet',
        manualAdjustmentHint:
            'Der Betrag konnte nicht automatisch einer Kategorie zugeordnet werden. Es kann sich z.B. um eine manuelle Anpassung handeln, die notwendig ist, falls Einnahmen oder Ausgaben nicht zum vorgesehenen Zeitpunkt oder in der erwarteten Höhe eingetreten sind, diese aber für die Ausschüttung berücksichtigt werden müssen. Bei etwaigen Unklarheiten klären wir die Sachverhalte gerne persönlich mit Ihnen auf.',
        realInvestorShare: 'Zahlungsbetrag',
        liquidityReserve: 'Liquiditätsrücklage (Soll)',
        liquidityReserveBuildUp: 'Aufbau Liquiditätsreserve',
        regularPayout: 'Reguläre Ausschüttung',
        distributionPayout: 'Ausschüttung Monat {month}',
        payoutTitle: 'Ausschüttungen',
        incomesExpensesTitle: 'Einnahmen/ Ausgaben',
        totalRevenue: 'Gesamteinnahmen',
        totalExpenses: 'Gesamtausgaben',
        report: 'Bericht',
        noBreakdownData: 'Detaildaten für diesen Zeitraum nicht verfügbar',
    },
    portalNavigation: {
        assets: {
            title: 'Meine Immobilie',
            description: 'Hier finden Sie alle relevanten Informationen über Ihr Eigentum.',
        },
        contact: {
            title: 'Kontaktformular',
            description: 'Kontaktieren Sie uns hier für alle Anfragen.',
        },
        documents: {
            title: 'Dokumente',
            description: 'Hier finden Sie alle relevanten Dokumente zu Ihrer Wohnung.',
        },
        finances: {
            title: 'Finanzinformationen',
            description: 'Die monatlichen Einnahmen und Ausgaben Ihrer Immobilie.',
            disabledText: 'Noch nicht <br /> verfügbar',
        },
        personalInformation: {
            title: 'Nutzerprofil',
            description: 'Passen Sie hier Ihre persönlichen Daten an.',
        },
    },
    portalProfile: {
        title: 'Profil Daten',
        personalDataTitle: 'Persönliche Daten',
        firstname: 'Vorname',
        lastname: 'Nachname',
        phoneNumber: 'Telefon',
        email: 'Email',
        address: 'Adresse',
        bankDataTitle: 'Bankdaten',
        notificationTitle: 'Benachrichtigungen',
        bankContact: 'Kontoinhaber',
        iban: 'IBAN',
        updateSuccess: 'Persönliche Daten aktualisiert',
        changeIban: 'IBAN ändern',
        changeDisabledDataRequestHint: `Wenn es einen besonderen Grund und Bedarf für eine Änderung gibt, kontaktieren Sie uns bitte per Email, damit wir Ihren Änderungsantrag prüfen und für Sie durchführen können: ${supportEmail}.`,
    },
    notification: {
        documentUploaded: 'Neues Dokument verfügbar',
        payoutPosted: 'Neue Ausschüttung erhalten',
        investmentNoteCreated: 'Investment Notiz erstellt',
        summitDateSet: 'Termin für Eigentümerversammlung',
    },
    selectDefaultValue: '--Bitte auswählen--',

    // portals pages
    selectInvestment: 'Meine Immobilien',
    sort: {
        newest: 'Neueste',
        oldest: 'Älteste',
    },

    uploadDocuments: {
        attachments: {
            title: 'Hier klicken um Datei auszuwählen oder Dateien hier ablegen',
            subTitle: 'JPG, PNG, MP4, PDF oder MS Office (max. 250Mb)',
        },
        fileNameInputTitle: 'Name der Datei',
        categoryInputTitle: 'Kategorie',
        saveButton: 'Alle speichern',
        cancelButton: 'Abbrechen',
        successTitle: 'Erfolgreicher Upload',
        successMessage: 'Dateien wurden hochgeladen.',
        failedMessage: 'Bitte stellen Sie sicher, dass alle erforderlichen Daten ausgefüllt sind.',
    },
    website: 'www.verwaltung.condogroup.de',

    heatingType: {
        [HeatingType.Coal]: 'Kohle',
        [HeatingType.PelletHeating]: 'Pellet Heizung',
        [HeatingType.NaturalGasHeavy]: 'Erdgas',
        [HeatingType.SteamDistrictHeating]: 'Ferndampfheizung',
        [HeatingType.BioEnergy]: 'Biomasse',
        [HeatingType.DistrictHeating]: 'Fernwärme',
        [HeatingType.Electricity]: 'Strom',
        [HeatingType.CombinedHeatAndPowerFossilFuels]: 'Kombiniert Strom/Fossil',
        [HeatingType.HydroEnergy]: 'Wasserkraft',
        [HeatingType.Gas]: 'Gas',
        [HeatingType.CombinedHeatAndPowerRenewableEnergy]: 'Kombiniert Strom/erneuerbar',
        [HeatingType.NaturalGasLight]: 'Naturgas',
        [HeatingType.WindEnergy]: 'Windenergie',
        [HeatingType.CombinedHeatAndPowerRegenerativeEnergy]: 'Kombiniert Strom/Regenerativ',
        [HeatingType.LiquidGas]: 'Flüssiggas',
        [HeatingType.EnvironmentalThermalEnergy]: 'Termalenergie',
        [HeatingType.HeatSupply]: 'Wärmeversorgung',
        [HeatingType.Geothermal]: 'Geothermal',
        [HeatingType.LocalHeating]: 'Lokal',
        [HeatingType.Wood]: 'Holz',
        [HeatingType.CombinedHeatAndPowerBioEnergy]: 'Kombiniert Strom/Biomasse',
        [HeatingType.SolarHeating]: 'Solar',
        [HeatingType.Oil]: 'Öl',
        [HeatingType.WoodChips]: 'Holzpellets',
        [HeatingType.NoInformation]: 'Keine Information',
    },
};
